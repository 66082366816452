import { createApi, retry } from "@reduxjs/toolkit/query/react"
import axios from "axios"

import { buildSerializedError } from "../../../lib/buildSerializedError"
import reportAxiosError from "../../../lib/reportAxiosError"
import buildTagsFrom403 from "../../actions/buildTagsFrom403"
import axiosBaseQuery from "../axiosBaseQuery"

export type GenericContent = {
  slug?: string
  title?: string
  content?: string
  image?: string | null
  type?: string
}

type Content = Record<string, GenericContent>

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1/content",
  }),
  endpoints: (builder) => ({
    getContent: builder.query<Content, void>({
      queryFn: retry(async () => {
        try {
          const { data } = await axios.get<Content>("/api-v1/content/")
          return { data }
        } catch (err) {
          reportAxiosError(err, {
            buildTagsFromError: buildTagsFrom403,
          })
          const error = buildSerializedError(err)
          return { error }
        }
      }),
      keepUnusedDataFor: 60 * 60 * 24, // 1 day in seconds
    }),
  }),
})

export const { useGetContentQuery } = contentApi
