import { createApi } from "@reduxjs/toolkit/query/react"

import { lifecycleModule } from "../../../components/consumer/Lifecycle/utils/lifecycleModules"
import axiosBaseQuery from "../axiosBaseQuery"
import {
  InterestRatesResponse,
  ProductRatesResponse,
  PropertyPriceResponse,
} from "./lifecycleFactorTypes"
import toastOnLifecycleApiError from "./toastOnLifecycleApiError"

export const lifecycleFactorApi = createApi({
  reducerPath: "lifecycleFactorApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1/mortgage",
  }),
  tagTypes: Object.values(lifecycleModule),
  endpoints: (builder) => ({
    getPropertyPriceByMortgageId: builder.query<PropertyPriceResponse, string>({
      query: (id) => ({
        url: `/${id}/property-value/`,
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: "/api-v1/mortgage/{mortgage.id}/property-value/",
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
      providesTags: (result, error, arg) => [
        { type: lifecycleModule.PROPERTY_VALUE, id: arg },
      ],
    }),
    getInterestRatesByMortgageId: builder.query<InterestRatesResponse, string>({
      query: (id) => ({
        url: `/${id}/interest-rates/`,
        reportContext: {
          ignoreStatuses: [401, 404],
          prettyURL: "/api-v1/mortgage/{mortgage.id}/interest-rates/",
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled, ["404"])
      },
      providesTags: (result, error, arg) => [
        { type: lifecycleModule.INTEREST_RATES, id: arg },
      ],
    }),
    requestProductRatesByMortgageId: builder.query<
      ProductRatesResponse | null,
      { id?: string; initialTerm?: string }
    >({
      query: ({ id, initialTerm }) => ({
        url: `/${id}/product-rates/`,
        params: { initial_term: initialTerm },
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: "/api-v1/mortgage/{mortgage.id}/product-rates/",
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
      providesTags: (result, error, arg) => [
        { type: lifecycleModule.PRODUCT_RATES, id: arg.id },
      ],
    }),
    requestCallByModuleByMortgageId: builder.mutation<
      null,
      {
        id: string
        module: lifecycleModule
        initialTerm?: string
      }
    >({
      query: ({ id, module, initialTerm }) => ({
        url: `/${id}/${module}/call-request/`,
        method: "POST",
        requestData: {
          initialTerm,
        },
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: `/api-v1/{mortgage.id}/${module}/call-request/`,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
      invalidatesTags: (result, error, arg) => [{ type: arg.module, id: arg.id }],
    }),
    confirmAllGood: builder.mutation<
      null,
      {
        id: string
        module: lifecycleModule
        initialTerm?: string
      }
    >({
      query: ({ id, module, initialTerm }) => ({
        url: `/${id}/${module}/confirm/`,
        method: "POST",
        requestData: {
          initialTerm,
        },
        reportContext: {
          ignoreStatuses: [401],
          prettyURL: `/api-v1/{mortgage.id}/${module}/confirm/`,
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
      invalidatesTags: (result, error, arg) => [{ type: arg.module, id: arg.id }],
    }),
    confirmIntroSeen: builder.mutation({
      query: () => ({
        url: `/intro-seen/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        await toastOnLifecycleApiError(queryFulfilled)
      },
    }),
  }),
})

export const {
  useGetPropertyPriceByMortgageIdQuery,
  useGetInterestRatesByMortgageIdQuery,
  useRequestProductRatesByMortgageIdQuery,
  useRequestCallByModuleByMortgageIdMutation,
  useConfirmAllGoodMutation,
  useConfirmIntroSeenMutation,
} = lifecycleFactorApi
