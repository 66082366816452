import { combineReducers } from "redux"

import { detailActivityApi } from "../../components/business/UserActivities/services/detailActivityApi"
import productRates from "../../components/consumer/Lifecycle/ProductRatesPage/productRatesSlice"
import { blogPostsApi } from "../services/blogPosts"
import { businessFileManagerApi } from "../services/business/fileManager"
import { businessProductSelectionApi } from "../services/business/productSelection"
import { reportingAdvisorsApi } from "../services/business/reportingAdvisors"
import { consumerFactorApi } from "../services/consumerFactor/consumerFactorApi"
import { contentApi } from "../services/content"
import { conversations } from "../services/conversations"
import { employeeFactorApi } from "../services/employeeFactor/employeeFactorApi"
import { eventApi } from "../services/eventApi"
import { learnApi } from "../services/learnApi"
import { lenderApi } from "../services/lender"
import { lifecycleFactorApi } from "../services/lifecycleFactor/lifecycleFactorApi"
import { lifecycleProspectsApi } from "../services/lifecycleProspects/lifecycleProspectsApi"
import { mortgagesApi } from "../services/mortgages"
import { notInterestedApi } from "../services/notInterested"
import { onboardingFactorApi } from "../services/onboardingFactor/onboardingFactorApi"
import { productSelectionApi } from "../services/productSelection"
import { singleSignOnApi } from "../services/singleSignOn"
import advisors from "../slices/advisors"
import imageEditor from "../slices/imageEditor"
import learnSession from "../slices/learnSession"
import loadApproval from "../slices/loadApproval"
import onboarding from "../slices/onboarding"
import persistant from "../slices/persistantSession"
import productSelection from "../slices/productSelection"
import ui from "../slices/ui"
import auth from "./auth"
import businessConsumer from "./business/consumer"
import consumerUpdate from "./business/consumerUpdate"
import permissionGroups from "./business/permissionGroups"
import charts from "./charts"
import ctaPrompt from "./consumer/ctaPrompt"
import route from "./route"

const store = combineReducers({
  auth,
  businessConsumer,
  charts,
  ctaPrompt,
  permissionGroups,
  route,
  productRates,
  consumerUpdate,
  ui,
  loadApproval,
  onboarding,
  [lifecycleFactorApi.reducerPath]: lifecycleFactorApi.reducer,
  [employeeFactorApi.reducerPath]: employeeFactorApi.reducer,
  [lifecycleProspectsApi.reducerPath]: lifecycleProspectsApi.reducer,
  [onboardingFactorApi.reducerPath]: onboardingFactorApi.reducer,
  [consumerFactorApi.reducerPath]: consumerFactorApi.reducer,
  [detailActivityApi.reducerPath]: detailActivityApi.reducer,
  [lenderApi.reducerPath]: lenderApi.reducer,
  [blogPostsApi.reducerPath]: blogPostsApi.reducer,
  [mortgagesApi.reducerPath]: mortgagesApi.reducer,
  [productSelectionApi.reducerPath]: productSelectionApi.reducer,
  [businessProductSelectionApi.reducerPath]: businessProductSelectionApi.reducer,
  [businessFileManagerApi.reducerPath]: businessFileManagerApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [singleSignOnApi.reducerPath]: singleSignOnApi.reducer,
  [notInterestedApi.reducerPath]: notInterestedApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [reportingAdvisorsApi.reducerPath]: reportingAdvisorsApi.reducer,
  [learnApi.reducerPath]: learnApi.reducer,
  [conversations.reducerPath]: conversations.reducer,
  productSelection,
  persistant,
  imageEditor,
  advisors,
  learnSession,
})

export default store
