import { createApi } from "@reduxjs/toolkit/query/react"

import { DEFAULT_LIST_SIZE } from "../../../constants/retain"
import { PaginatedResponse } from "../../../types/retain"
import axiosBaseQuery from "../axiosBaseQuery"

type Product = {
  id: string
}

export type SelectedProduct = {
  consumerId: string
  accountNumber: string
  applicantName: string
  switchState:
    | "without-advice"
    | "internal-advice"
    | "external-advice"
    | "with-switch-support"
  selectedProductOptions: Product[] | undefined
  isLocked: boolean
  productOptionsAvailable?: boolean
}

type ProductsResponse = PaginatedResponse<SelectedProduct>

export const PORTAL_LIST_TAG = "PortalList"

export const businessProductSelectionApi = createApi({
  reducerPath: "businessProductSelectionApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  tagTypes: [PORTAL_LIST_TAG],
  endpoints: (builder) => ({
    getProducts: builder.query<ProductsResponse, { offset: number }>({
      query: ({ offset }) => ({
        url: `/portal-list/`,
        params: { limit: DEFAULT_LIST_SIZE, offset },
      }),
      providesTags: [PORTAL_LIST_TAG],
    }),
    postProductOutcome: builder.mutation<
      undefined,
      {
        selectedProperties: string[]
        consumerId: string
      }
    >({
      query: ({ selectedProperties, consumerId }) => ({
        url: `/consumer/${consumerId}/portal-outcome/`,
        method: "POST",
        requestData: { selectedProperties },
      }),
      invalidatesTags: [PORTAL_LIST_TAG],
    }),
  }),
})

export const { useGetProductsQuery, usePostProductOutcomeMutation } =
  businessProductSelectionApi
