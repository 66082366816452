/**
 * Comments have been added to help guide naming new ids
 * and renaming them when it's possible
 */

export enum ContentId {
  /**
   * PROPERTY_VALUE_BASED_ON
   * Component: src/components/consumer/Lifecycle/PropertyPricePage/PropertyValuePage.tsx
   * Location: /property-value/:propertyId?
   * Suggestion: propertyValue.basedAccordion.content
   */
  PROPERTY_VALUE_BASED_ON = "propertyValueBasedOn",
  ACCOUNT_NUMBER_CONFIRMATION_MDX = "switch.accountNumber.infoBoxMdxContent",
  ACCOUNT_NUMBER_INFO_BOX_TITLE = "switch.accountNumber.infoBoxTitle",
  ACCOUNT_NUMBER_INFO_BOX_BODY = "switch.accountNumber.infoBoxBody",
  /**
   * ADDITIONAL_SIDEBAR_DISCLAIMER
   * Component: src/components/consumer/Policies.tsx
   * Location: All consumer side routes
   * Suggestion: policiesTab.additionalDisclaimer.content
   */
  ADDITIONAL_SIDEBAR_DISCLAIMER = "additionalSidebarDisclaimer",
  /**
   * ALERTS_DISABLED
   * Component: src/components/consumer/LenderCTAPages/LenderAlertsDisabledPage.tsx
   * Location: /portal/alerts-disabled
   * Suggestion:
   *  - lender.alertsDisabled.heroImage
   *  - lender.alertsDisabled.headerTitle
   *  - lender.alertsDisabled.bodyMarkdown
   */
  ALERTS_DISABLED = "alertsDisabled",
  /**
   * ALREADY_ACTIONED_FORM_SUBMIT
   * Component: src/components/consumer/AlreadyActionedForm/AlreadyActionedForm.tsx
   * Location: /portal/overview/your-options/:propertyId?
   * Suggestion: lender.actionedForm.submitButton.submitContent
   */
  ALREADY_ACTIONED_FORM_SUBMIT = "alreadyActionedFormSubmit",
  /**
   * ALREADY_ACTIONED_FORM_SUBMIT_LOADING
   * Component: src/components/consumer/AlreadyActionedForm/AlreadyActionedForm.tsx
   * Location: /portal/overview/your-options/:propertyId?
   * Suggestion: lender.actionedForm.submitButton.loadingContent
   */
  ALREADY_ACTIONED_FORM_SUBMIT_LOADING = "alreadyActionedFormSubmitLoading",
  /**
   * Is ARTICLE_PRIMARY_CTA_ICON unused in the firm data?
   */
  ARTICLE_PRIMARY_CTA_ICON = "articlePrimaryCtaIcon",
  ARTICLE_SECONDARY_CTA = "articleSecondaryCta",
  ARTICLE_SECONDARY_CTA_URL = "articleSecondaryCtaUrl",
  ASK_FOR_HELP = "askForHelp",
  ASK_FOR_HELP_CTA = "askForHelpCta",
  BOOK_APPOINTMENT = "bookAppointment",
  CALL_TIMES = "callTimes",
  CANT_SWITCH_INTRO_IMAGE = "switch.cantSwitch.introImage",
  CANT_SWITCH_TITLE = "switch.cantSwitch.headerTitle",
  CANT_SWITCH_CONTENT = "switch.cantSwitch.descriptionContent",
  /**
   * CONSUMER_ACTION_CHANGE_DATA_MESSAGE
   * Component: src/components/business/PopIn/ChangeDataPopIn.tsx
   * Suggestion: business.changeData.explanationContent
   * The rare BSide whitelabelling. This needs to be discussed.
   */
  CONSUMER_ACTION_CHANGE_DATA_MESSAGE = "consumerActionChangeDataMessage",
  COOKIE_POLICY_POPOVER_ESSENTIAL_DROPDOWN = "cookiePolicyPopoverEssentialDropdown",
  /**
   * Component: src/components/common/CookiePolicyPopover.tsx
   * Suggestion: cookiePopover.functionalTitle
   * Do these actually change for any firms?
   */
  COOKIE_POLICY_POPOVER_FUNCTIONAL_DROPDOWN = "cookiePolicyPopoverFunctionalDropdown",
  CURRENT_SITUATION_ALL_GOOD = "currentSituation.allGood",
  CURRENT_SITUATION_SECONDARY_CTA_URL = "currentSituation.secondaryCta.url",
  CURRENT_SITUATION_TITLE = "currentSituation.title",
  CURRENT_SITUATION_ZERO_MORTAGES_URL = "currentSituation.secondaryZeroMortgages.url",
  FOLLOW_ON_PAYMENTS_BASED_ON__ACTIVE__MDX = "followOnPayments.basedOnActiveMdxContent",
  FOLLOW_ON_PAYMENTS_BASED_ON__EXPIRED__MDX = "followOnPayments.basedOnExpiredMdxContent",
  FOLLOW_ON_PAYMENTS_RATE_TABLE_HEADER = "followOnPayments.rateTableHeaderContent",
  /**
   * HELP
   * Component: src/components/consumer/LenderExpiryPage/ContentBoxes/MoreOptionsContentBox.tsx
   * Location: /portal/overview/your-options/:propertyId
   * Suggestion: currentSituation.moreOptionsContentBox.helpContent
   */
  HELP = "help",
  /**
   * JOURNEY_EXPLORE
   * Is this unused now?
   */
  JOURNEY_EXPLORE = "journeyExplore",
  /**
   * JOURNEY_VIEW_MORTGAGE
   * Component: src/components/consumer/PortalButtons/ViewMortgageButton.tsx
   * Location: /portal/learn/article/:slug/:propertyId?
   * Suggestion: article.viewMortgage.title (but we could convert this to .labelContent)
   */
  JOURNEY_VIEW_MORTGAGE = "journeyViewMortgage",
  // JOURNEY_FOOTER_IMAGE => we have references to "lender", this or portal needs to be decided
  JOURNEY_FOOTER_IMAGE = "lender.homepage.footerImage",
  /**
   * IF_CANT_SWITCH
   * No longer used?
   */
  IF_CANT_SWITCH = "ifCantSwitch",
  KEY_INFORMATION_PRODUCT = "keyInformationProduct",
  /**
   * Component: src/components/consumer/BlogPosts/PortalArticle/PortalLearn.tsx
   * Location: /portal/learn/:propertyId
   * Suggestion: split into portal.home.title and portal.home.introContent (portal.learn)
   */
  LEARN = "learn",
  KBA_LOGIN_HEADER = "kbaLogin.headerContent",
  KBA_LOGIN_INTRO = "kbaLogin.introMarkdownContent",
  /**
   * Component: src/components/consumer/LenderCTAPages/LenderNoIdPage.tsx
   * Location: /portal/no-id
   * Suggestion: split into portal.unidentified.title, .image, .emailMarkdown
   */
  NO_ID = "noId",
  NO_UNSUBSCRIBE_TOAST = "noUnsubscribeToast",
  NOTIFICATIONS_MENU_ITEM = "notificationsMenuItem",
  PAGE_CONFIG_OPTIONS_EXTERNAL_ADVICE_BUTTON_LABEL = "externalAdviceButton.label",
  OPTIONS_PAUSED_BOX_CONTENT = "options.pausedBox.content",
  OPTIONS_PAUSED_BOX_TITLE = "options.pausedBox.title",
  EXTERNAL_ADVICE_BUTTON_ICON = "externalAdviceButton.iconImage",
  PAGE_CONFIG_OPTIONS_ICON = "options.icon",
  PAGE_CONFIG_OPTIONS_FULL_WIDTH_IMAGE = "options.fullWidthImage",
  INTERNAL_ADVICE_BUTTON_LABEL = "internalAdviceButton.label",
  INTERNAL_ADVICE_BUTTON_TITLE = "internalAdviceButton.title",
  INTERNAL_ADVICE_BUTTON_DESCRIPTION = "internalAdviceButton.description",
  WITH_SWITCH_SUPPORT_BUTTON_LABEL = "withSwitchSupportButton.label",
  WITH_SWITCH_SUPPORT_BUTTON_TITLE = "withSwitchSupportButton.title",
  WITH_SWITCH_SUPPORT_BUTTON_DESCRIPTION = "withSwitchSupportButton.description",
  MORE_OPTIONS_BUTTON_LABEL = "moreOptionsButton.label",
  SEE_RATES_BUTTON_LABEL = "seeRatesButton.label",
  EXPLORE_PROCESS_BUTTON_LABEL = "exploreProcessButton.labelContent",
  OPTIONS_ACTIONED_BOX_CONTENT = "options.actionedContentBox.content",
  OPTIONS_ACTIONED_BOX_TITLE = "options.actionedContentBox.title",
  STOP_ALERTS_BUTTON_LABEL = "stopAlertsButton.label",
  SWITCH_BUTTON_LABEL = "switchButton.labelContent",
  /**
   * PHONE_NUMBER
   * Component: src/components/consumer/LenderOptionsPage/ContentBoxes/ContactDetailsBox/ContactDetailsBox.tsx
   * Location:
   * Suggestion: options.contactDetails.phoneContent
   */
  PHONE_NUMBER = "phoneNumber",
  /**
   * PHONE_US_IF
   * Component: src/components/consumer/LenderOptionsPage/ContentBoxes/ContactDetailsBox/ContactDetailsBox.tsx
   * Location:
   * Suggestion: options.contactDetails.introContent
   */
  PHONE_US_IF = "phoneUsIf",
  /**
   * Component:
   * Location:
   * Suggestion:
   */
  QUICK_SELECT_CASE_STARTED = "quickSelectCaseStarted",
  QUICK_SELECT_MEETING_BOOKED = "quickSelectMeetingBooked",
  QUICK_SELECT_NOT_INTERESTED = "quickSelectNotInterested",
  QUICK_SELECT_RESCHEDULE_MEETING = "quickSelectRescheduleMeeting",
  /**
   * Component:
   * Location:
   * Suggestion:
   */
  SCHEDULE_CALL = "scheduleCall",
  SET_REMINDER_CTA = "setReminderCta",
  SET_REMINDER_PAGE = "setReminderPage",
  STOP_ALERTS = "stopAlerts",
  STOP_ALERTS_IMAGE = "stopAlerts.image",
  STOP_ALERTS_MULTIPLE_MORTGAGES = "stopAlertsMultipleMortgages",
  STOP_ALERTS_SINGLE_MORTGAGE = "stopAlertsSingleMortgage",
  SUBSCRIBE_TO_ALL = "subscribeToAll",
  SUPPORT_CTA = "supportCta",
  THANKS_MODAL_TITLE = "thanksModalTitle",
  /**
   * Component:
   * Location:
   * Suggestion:
   */
  UNSUBSCRIBE_CONTACT_DETAILS = "unsubscribeContactDetails",
  UNSUBSCRIBE_CONTACT_DETAILS__AUTH = "unsubscribeContactDetailsAuth",
  UNSUBSCRIBE_CONTACT_DETAILS__NO_AUTH = "unsubscribeContactDetailsNoAuth",
  UNSUBSCRIBE_INFO_BOX = "unsubscribeInfoBox",
  NOT_INTERESTED_MODAL = "notInterestedModal",
  UNSUBSCRIBE_PRODUCT_ALERT = "unsubscribeProductAlert",
  UNSUBSCRIBE_SUBMIT_BUTTON = "unsubscribeSubmitButton",
  UNSUBSCRIBE_TO_ALL = "unsubscribeToAll",
  UNSUBSCRIBE_TOAST__MDX = "unsubscribeToastMdx",
  UPDATE_NOTIFICATIONS = "updateNotifications",
  UPDATE_NOTIFICATIONS_MORTGAGE_SELECTOR = "updateNotificationsMortgageSelector",
  UPDATE_NOTIFICATIONS_PREAMBLE = "updateNotificationsPremable",
  UPDATE_NOTIFICATIONS_SUBMIT = "updateNotificationsSubmit",
  UPDATE_NOTIFICATIONS_SUBMIT_LOADING = "updateNotificationsSubmitLoading",
  VISIT_PRODUCT_PORTAL = "visitProductPortal",
  WHY_PAYMENTS_CHANGE_POST_EXPIRY = "whyPaymentChangePostExpiry",
  WHY_PAYMENTS_CHANGE_PRE_EXPIRY = "whyPaymentChangePreExpiry",
  ZERO_MORTGAGES = "zeroMortgages",
  DISCARD_TITLE = "discardTitle",
  DISCARD_CANCEL_BUTTON_LABEL = "discardCancelButton",
  DISCARD_CONFIRM_BUTTON_LABEL = "discardConfirmButton",
  DISCARD_PREAMBLE = "discardPreamble",
  LIFECYCLE_ZERO_MORTGAGES = "lifecycleZeroMortgages",
  /**
   * Menu blocks should be their own const
   */
  /**
   * Component: src/components/consumer/Menu/Atoms/ActionedMortgage.tsx
   * Suggestion: menu.actionedMortgage
   */
  MENU_ACTIONED_MORTGAGE = "menuActionedMortgage",
  MENU_CHANGE_PASSWORD = "menuChangePassword",
  MENU_CONTACT_PREFERENCES = "menuContactPreferences",
  MENU_CONTAINER = "menuContainer",
  MENU_HOME = "menuHome",
  MENU_JOURNEY_VIEW_MORTGAGE = "menuJourneyViewMortgage",
  MENU_JOURNEY_EXPLORE = "menuJourneyExplore",
  MENU_LOG_IN = "menuLogIn",
  MENU_LOG_OUT = "menuLogOut",
  MENU_MANAGE_NOTIFICATIONS = "menuManageNotifications",
  MENU_MORTGAGE_HEALTH = "menuMortgageHealth",
  MENU_MORTGAGES_HOME = "menuMortgagesHome",
  MENU_OVERVIEW = "menuOverview",
  MENU_REQUEST_CALL = "menuRequestCall",
  MENU_RESET_PASSWORD = "menuResetPassword",
  /**
   * Component:
   * Location:
   * Suggestion:
   */
  MORTGAGE_HEALTH_PAGE_INFO_CARDS = "mortgageHealthPageInfoCards",
  MORTGAGE_HOME_ALERT_CHANGE = "mortgageHomePageAlertChange",
  MORTGAGE_INTRO_PAGE_GREETING_MDX = "mortgageIntroPageGreetingMDX",
  MORTGAGE_HEALTH_PAGE_PROPERTY_INTRO_COUNT_MDX = "mortgageHealthPagePropertyIntroCountMdx",
  MORTGAGE_INTRO_PAGE_TITLE = "mortgageIntroPageTitle",
  MORTGAGE_INTRO_PAGE_PREAMBLE = "mortgageIntroPagePreamble",
  MORTGAGE_OVERVIEW_POP_IN_TITLE = "mortgageOverview.popIn.titleContent",
  MORTGAGE_OVERVIEW_WHY_HAPPENING_IMAGE = "mortgageOverview.whyHappening.image",
  MORTGAGE_OVERVIEW_WHY_HAPPENING_MDX_CONTENT = "mortgageOverview.whyHappening.mdxContent",
  PROPERTY_HEALTH_CARD_ALERT_CHANGE = "propertyHealthCardAlertChange",
  PROPERTY_HEALTH_CARD_BODY_CHANGE = "propertyHealthCardBodyChange",
  PROPERTY_HEALTH_CARD_CTA_CHANGE = "propertyHealthCardCtaChange",
  PROPERTY_HEALTH_CARD_ALERT_NO_CHANGE = "propertyHealthCardAlertNoChange",
  PROPERTY_HEALTH_CARD_BODY_NO_CHANGE = "propertyHealthCardBodyNoChange",
  PROPERTY_HEALTH_CARD_CTA_NO_CHANGE = "propertyHealthCardCtaNoChange",
  PROPERTY_HEALTH_CARD_ALERT_SNOOZE = "propertyHealthCardAlertSnooze",
  PROPERTY_HEALTH_CARD_BODY_SNOOZE = "propertyHealthCardBodySnooze",
  PROPERTY_HEALTH_CARD_ALERT_UNABLE_TO_CHECK = "propertyHealthCardAlertUnableToCheck",
  PROPERTY_HEALTH_CARD_BODY_UNABLE_TO_CHECK = "propertyHealthCardBodyUnableToCheck",
  PRODUCT_RATES_ESTIMATED_SWITCHING = "productRatesEstimatedSwitching",
  PRODUCT_RATES_MODAL_APRC = "productRatesModalAprc",
  PRODUCT_RATES_MODAL_MONTHLY_COST_OF_SWITCHING = "productRatesModalMonthlyCostOfSwitching",
  PRODUCT_RATES_MODAL_EARLY_REPAYMENT_CHARGES_INFO_LABEL = "productRatesModalEarlyRepaymentChargesInfoLabel",
  PRODUCT_RATES_MODAL_EARLY_REPAYMENT_CHARGES_MDX = "productRatesModalEarlyRepaymentCharges",
  PRODUCT_RATES_MODAL_LENDER_ARRANGEMENT_FEES = "productRatesModalLenderArrangementFees",
  PRODUCT_RATES_MODAL_LEGAL_FEES = "productRatesModalLegalFees",
  PRODUCT_RATES_MODAL_LEGAL_FEES_INFO_LABEL = "productRatesModalLegalFeesInfoLabel",
  INTEREST_RATES_MODAL_CONTENT_PAGE_1 = "interestRatesModalContentPage1",
  INTEREST_RATES_MODAL_CONTENT_PAGE_2 = "interestRatesModalContentPage2",
  INTEREST_RATES_MODAL_CONTENT_PAGE_3 = "interestRatesModalContentPage3",
  EXPIRY_DATE_MODAL_CONTENT_PAGE_1 = "expiryDateModalContentPage1",
  EXPIRY_DATE_MODAL_CONTENT_PAGE_2 = "expiryDateModalContentPage2",
  EXPIRY_DATE_MODAL_CONTENT_PAGE_3 = "expiryDateModalContentPage3",
  LOAN_TO_VALUE_MODAL_CONTENT_PAGE_1 = "loanToValueModalContentPage1",
  LOAN_TO_VALUE_MODAL_CONTENT_PAGE_2 = "loanToValueModalContentPage2",
  LOAN_TO_VALUE_MODAL_CONTENT_PAGE_3 = "loanToValueModalContentPage3",
  PRODUCT_RATES_MODAL_CONTENT_PAGE_1 = "productRatesModalContentPage1",
  PRODUCT_RATES_MODAL_CONTENT_PAGE_2 = "productRatesModalContentPage2",
  PRODUCT_RATES_MODAL_CONTENT_PAGE_3 = "productRatesModalContentPage3",
  PROPERTY_VALUE_MODAL_CONTENT_PAGE_1 = "propertyValueModalContentPage1",
  PROPERTY_VALUE_MODAL_CONTENT_PAGE_2 = "propertyValueModalContentPage2",
  PROPERTY_VALUE_MODAL_CONTENT_PAGE_3 = "propertyValueModalContentPage3",
  LOAN_TO_VALUE_ALERT_CARD = "loanToValueAlertCard",
  PRODUCT_RATES_IF_ELIGIBLE = "productRatesIfEligible",
  PRODUCT_RATES_BEST_DEAL = "productRatesBestDeal",
  MORTGAGE_HOME_NO_CHANGE_CONTENT_MDX = "mortgageHomeNoChangeContentMDX",
  MORTGAGE_HOME_CHANGE_CONTENT = "mortgageHomeChangeContent",
  MORTGAGE_INTRO_CTA_LABEL = "mortgageIntroCtaLabel",
  /**
   * Component:
   * Location:
   * Suggestion:
   */
  ONBOARDING_LOGIN_ARTICLE_ID = "onboardingLoginArticleId",
  ONBOARDING_POLICIES_TOOLTIP_LEGAL_NAME = "onboardingPoliciesTooltipLegalName",
  ONBOARDING_POLICIES_TOOLTIP_COMPANY_NUMBER = "onboardingPoliciesTooltipCompanyNumber",
  ONBOARDING_POLICIES_TOOLTIP_COMPANY_ADDRESS = "onboardingPoliciesTooltipCompanyAddress",
  ONBOARDING_POLICIES_TOOLTIP_COMPANY_POSTCODE = "onboardingPoliciesTooltipCompanyPostcode",
  ONBOARDING_POLICIES_TOOLTIP_COMPANY_TYPE = "onboardingPoliciesTooltipCompanyType",
  ONBOARDING_POLICIES_TOOLTIP_REGISTERED_COUNTRIES = "onboardingPoliciesTooltipRegisteredCountries",
  ONBOARDING_POLICIES_TOOLTIP_REGISTERED_ADDRESS = "onboardingPoliciesTooltipRegisteredAddress",
  ONBOARDING_POLICIES_TOOLTIP_FCA_NUMBER = "onboardingPoliciesTooltipFcaNumber",
  ONBOARDING_POLICIES_TOOLTIP_PRIVACY_POLICY_URL = "onboardingPoliciesTooltipPrivacyPolicyUrl",
  ONBOARDING_POLICIES_TOOLTIP_TERMS_OF_BUSINESS_URL = "onboardingPoliciesTooltipTermsOfBusinessUrl",
  ONBOARDING_POLICIES_TOOLTIP_ACCESSIBILITY_POLICY_URL = "onboardingPoliciesTooltipAccessibilityPolicyUrl",
  ONBOARDING_POLICIES_TOOLTIP_WEB_DISCLAIMER = "onboardingPoliciesTooltipWebDisclaimer",
  ONBOARDING_POLICIES_TOOLTIP_OWNER_OCCUPIED_EMAIL_DISCLAIMER = "onboardingPoliciesTooltipOwnerOccupiedEmailDisclaimer",
  ONBOARDING_POLICIES_TOOLTIP_BUY_TO_LET_EMAIL_DISCLAIMER = "onboardingPoliciesTooltipBuyToLetEmailDisclaimer",
  ONBOARDING_POLICIES_TOOLTIP_TWO_FACTOR_REQUIRED = "onboardingPoliciesTooltipTwoFactorRequired",
  ONBOARDING_UPLOAD_CRM_GUIDE_LINK = "onboardingUploadCrmGuidLink",
  /**
   * Should these fill and styles become part of a "style.???" category e.g. "styles.defaultButton.fill"
   */
  DEFAULT_BUTTON_FILL = "defaultButtonFill",
  DEFAULT_BUTTON_STYLE = "defaultButtonStyle",
  // Maybe we should change the use of "primary" language in other places to represent that it's "first-child" style styling
  FIRST_BUTTON_FILL = "firstButtonFill",
  FIRST_BUTTON_STYLE = "firstButtonStyle",
  LENDER_SWITCH_PAGE_FOOTER_IMAGE_LOW = "lender.switch.footerImage.lowRes.image",
  LENDER_SWITCH_PAGE_FOOTER_IMAGE_HIGH = "lender.switch.footerImage.highRes.image",
  SUBMIT_BUTTON_LABEL = "kbaLogin.submitButton.labelContent",
  SUBMIT_BUTTON_SUBMITTING = "kbaLogin.submitButton.submittingContent",
  PRODUCT_SELECTION_BUTTON_LABEL = "productSelectionButton.labelContent",
  CONTINUE_REVIEW_BUTTON_LABEL = "continueReviewButton.labelContent",
}

export const CurrentSituationContentId = {
  WITHOUT_ADVICE_HEADER: "currentSituation.withoutAdviceContentBox.headerTitle",
  WITHOUT_ADVICE_DESCRIPTION:
    "currentSituation.withoutAdviceContentBox.descriptionContent",
  EXPIRY_PAUSED_BOX_TITLE: "currentSituation.pausedContentBox.title",
  EXPIRY_PAUSED_BOX_BODY: "currentSituation.pausedContentBox.content",
  EXPIRY_ACTIONED_BOX_TITLE: "currentSituation.actionedContentBox.title",
  EXPIRY_ACTIONED_BOX_BODY: "currentSituation.actionedContentBox.content",
  PREAMBLE_EXPIRED_MORTGAGE_MDX:
    "currentSituation.lenderPreamble.expiredMortgage.mdxContent",
  PREAMBLE_ACTIVE_MORTGAGE_MDX:
    "currentSituation.lenderPreamble.activeMortgage.mdxContent",
  EXPIRY_SWITCHING_BOX_TITLE: "currentSituation.switchingContentBox.title",
  EXPIRY_SWITCHING_BOX_BODY: "currentSituation.switchingContentBox.content",
  EXPIRY_NOT_INTERESTED_BOX_TITLE: "currentSituation.notInterestedContentBox.title",
  EXPIRY_NOT_INTERESTED_BOX_BODY: "currentSituation.notInterestedContentBox.content",
  EXPIRY_INCOMPLETE_BOX_TITLE: "currentSituation.incompleteContentBox.title",
  EXPIRY_INCOMPLETE_BOX_BODY: "currentSituation.incompleteContentBox.content",
  EXPIRY_EXTERNAL_INCOMPLETE_BOX_TITLE:
    "currentSituation.externalIncompleteContentBox.title",
  EXPIRY_EXTERNAL_INCOMPLETE_BOX_BODY:
    "currentSituation.externalIncompleteContentBox.content",
} as const

export type CurrentSituationContentId =
  (typeof CurrentSituationContentId)[keyof typeof CurrentSituationContentId]

export const LenderHomepageContentId = {
  EXPLORE_BUTTON_IMAGE: "lender.homepage.learnButton.iconImage",
  EXPLORE_BUTTON_LABEL: "lender.homepage.learnButton.labelTitle",
  FOOTER_IMAGE: "lender.homepage.footerImage",
  HEADER: "lender.homepage.headerTitle",
  INTRO: "lender.homepage.introContent",
  MORTAGES_BUTTON_IMAGE: "lender.homepage.mortgagesButton.iconImage",
  MORTAGES_BUTTON_LABEL: "lender.homepage.mortgagesButton.labelTitle",
} as const

export type LenderHomepageContentId =
  (typeof LenderHomepageContentId)[keyof typeof LenderHomepageContentId]

export const LenderLearnContentId = {
  HERO_IMAGE: "lenderLearn.heroImage",
} as const

export type LenderLearnContentId =
  (typeof LenderLearnContentId)[keyof typeof LenderLearnContentId]

export const LenderOptionsContentId = {
  ZERO_MORTGAGES_IMAGE: "lender.options.zeroMortgages.image",
  ZERO_MORTGAGES_HEADER: "lender.options.zeroMortgages.headerContent",
  ZERO_MORTGAGES_BODY: "lender.options.zeroMortgages.bodyContent",
  INTRO_TITLE: "lender.options.intro.title",
  INTRO_WITHOUT_ADVICE_CONTENT: "lender.options.intro.withoutAdvice.content",
  INTRO_EXTERNAL_ADVICE_CONTENT: "lender.options.intro.externalAdvice.content",
  INTRO_INTERNAL_ADVICE_CONTENT: "lender.options.intro.internalAdvice.content",
  INTRO_WITH_SWITCH_SUPPORT_CONTENT: "lender.options.intro.withSwitchSupport.content",
} as const

export type LenderOptionsContentId =
  (typeof LenderOptionsContentId)[keyof typeof LenderOptionsContentId]

export const LenderNotInterestedLabelContentId = {
  USED_ADVISOR: "lender.notInterestedLabel.advisorContent",
  SOLD_PROPERTY: "lender.notInterestedLabel.soldContent",
  DIFFERENT_LENDER: "lender.notInterestedLabel.differentContent",
  NOT_SWITCHING: "lender.notInterestedLabel.notSwitchingContent",
  PREFER_NOT_TO_SAY: "lender.notInterestedLabel.preferContent",
} as const

export type LenderNotInterestedLabelContentId =
  (typeof LenderNotInterestedLabelContentId)[keyof typeof LenderNotInterestedLabelContentId]

// We need to hold this one because if we remove it the types will error as currently all our content ids are strings because of usage of this type
export const LenderRemindProductsContentId = {
  READY_HEADER: "lender.remindProducts.ready.title",
  REMIND_BUTTON_LABEL: "lender.remindProducts.remindButton.labelContent",
  SUCCESS_BUTTON_LABEL: "lender.remindProducts.successButton.labelContent",
  SUCCESS_HEADER: "lender.remindProducts.success.title",
  SUCCESS_CONTENT: "lender.remindProducts.success.copyContent",
}

export type LenderRemindProductsContentId =
  (typeof LenderRemindProductsContentId)[keyof typeof LenderRemindProductsContentId]
