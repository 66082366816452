import { Dispatch } from "redux"

import { blogPostsApi } from "./blogPosts"
import { businessProductSelectionApi } from "./business/productSelection"
import { consumerFactorApi } from "./consumerFactor/consumerFactorApi"
import { conversations } from "./conversations"
import { employeeFactorApi } from "./employeeFactor/employeeFactorApi"
import { lenderApi } from "./lender"
import { lifecycleFactorApi } from "./lifecycleFactor/lifecycleFactorApi"
import { lifecycleProspectsApi } from "./lifecycleProspects/lifecycleProspectsApi"
import { mortgagesApi } from "./mortgages"
import { onboardingFactorApi } from "./onboardingFactor/onboardingFactorApi"
import { productSelectionApi } from "./productSelection"

function resetApiStateOnAllServices(dispatch: Dispatch) {
  dispatch(blogPostsApi.util?.resetApiState())
  dispatch(businessProductSelectionApi.util?.resetApiState())
  dispatch(consumerFactorApi.util?.resetApiState())
  dispatch(employeeFactorApi.util?.resetApiState())
  dispatch(lenderApi.util?.resetApiState())
  dispatch(lifecycleFactorApi.util?.resetApiState())
  dispatch(lifecycleProspectsApi.util?.resetApiState())
  dispatch(mortgagesApi.util?.resetApiState())
  dispatch(onboardingFactorApi.util?.resetApiState())
  dispatch(productSelectionApi.util?.resetApiState())
  dispatch(conversations.util?.resetApiState())
}

export default resetApiStateOnAllServices
