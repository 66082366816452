import { createApi } from "@reduxjs/toolkit/dist/query/react"

import { PaginatedResponse } from "../../../types/retain"
import axiosBaseQuery from "../axiosBaseQuery"

export type Conversation =
  | "svr-management"
  | "svr-compliance"
  | "svr-operational"
  | "eot-transactional"
  | "eot-educational"
  | null

export type Workflow =
  | "learn-more"
  | "understanding"
  | "eot-educational-intent"
  | "switching-intent"
  | "product-selection"
  | null

export type MortgageAlert = {
  type: "svr" | "eot" | null
  mortgageIds: string[]
}

export type WhatNextProperty = {
  workflow?: Workflow
  propertyId: string
  arguments?: {
    conversation: Conversation | null
    understandingSubtopicId: string | null
    previousCta: string | null
    learningStarted: boolean
    learningFinished: boolean
    // Potentially other args
  }
  leadMortgageIds: string[]
  alerts: MortgageAlert[]
}

type RawWhatNextResponse = PaginatedResponse<WhatNextProperty>

export type WhatNextResponse = RawWhatNextResponse & {
  hasMultipleProperties: boolean
}

type ArgumentsAndWorkflow = Pick<WhatNextProperty, "arguments" | "workflow">

export const conversations = createApi({
  reducerPath: "conversations",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  endpoints: (builder) => ({
    getWhatNext: builder.query<WhatNextResponse, void>({
      query: () => ({
        url: "/whats-next/",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      keepUnusedDataFor: 0.3,
      transformResponse: (rawResponse: RawWhatNextResponse) => ({
        ...rawResponse,
        hasMultipleProperties: rawResponse.results.length > 1,
      }),
    }),
    postUnderstandingComplete: builder.mutation<
      ArgumentsAndWorkflow,
      {
        propertyId: string
      }
    >({
      query: ({ propertyId }) => ({
        url: `/property/${propertyId}/understanding-complete/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
    }),
    postStopAlerts: builder.mutation<
      ArgumentsAndWorkflow,
      {
        propertyId: string
        choice: string
      }
    >({
      query: ({ propertyId, choice }) => ({
        url: `/property/${propertyId}/stop-alerts/`,
        method: "POST",
        ignoreStatuses: [401],
        requestData: {
          category: choice,
        },
      }),
    }),
  }),
})

export const {
  useGetWhatNextQuery,
  usePostUnderstandingCompleteMutation,
  usePostStopAlertsMutation,
} = conversations
