import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import minMax from "dayjs/plugin/minMax"
import objectSupport from "dayjs/plugin/objectSupport"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)
dayjs.extend(arraySupport)
dayjs.extend(customParseFormat)
dayjs.extend(minMax)
dayjs.extend(duration)
dayjs.extend(objectSupport)
