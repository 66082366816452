import { ReactNode, memo, useEffect, useState } from "react"

import { Container, ErrorMessage, LoadingIndicator } from "../../../components/common"
import { useAppSelector } from "../../store"

import { useGetContentQuery } from "./index"

let loadingDisplayTimeout: NodeJS.Timeout

/* This will return an error page if there is an error loading content */
const MemoWrapper = memo(function ContentApiStatusWrapper({
  children,
}: {
  children: ReactNode
}) {
  const firm = useAppSelector((state) => state.auth.firm)
  const { isError, isLoading } = useGetContentQuery(undefined, {
    skip: !firm,
  })
  const [shouldShowLoading, setShouldShowLoading] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setShouldShowLoading(false)
      loadingDisplayTimeout = setTimeout(() => {
        setShouldShowLoading(true)
      }, 500)
    } else {
      clearTimeout(loadingDisplayTimeout)
      setShouldShowLoading(false)
    }
    return () => {
      clearTimeout(loadingDisplayTimeout)
    }
  }, [isLoading])

  if (isError) {
    return (
      <Container bg="transparent">
        <ErrorMessage msg="There was an error loading content. Please try again later." />
      </Container>
    )
  }

  if (isLoading) {
    if (!shouldShowLoading) {
      // Loading indicator is not shown if the content loads quickly
      return null
    }
    // We pass in the primary color of the firm because the Preface component which overrides this styling is not rendered in this context
    return <LoadingIndicator color={firm?.primaryColor} />
  }

  return <>{children}</>
})

export default MemoWrapper
